
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonTextarea, modalController,
    IonLoading
} from '@ionic/vue';
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import {currentContest} from "@/util/ContestService";
import {fetchResultPoints, ResultPoints} from "@/util/ResultService";
import {Cheese} from "@/util/CheeseService";
import {isLoading, setLoadingState} from "@/util/LocalStateService";
import VerifyRating from "@/components/VerifyRating.vue";

export default defineComponent({
    name: "Rating",
    components: {
        Header, IonPage, IonGrid, IonRow, IonCol, IonContent,
        IonCard, IonCardContent, IonCardHeader, IonCardTitle,
        IonButton, IonTextarea, IonLoading
    },
    data() {
        return {
            error: {
                show: false,
                answer9: {lable: 'Das Produkt ist als Innovation erkennbar und verdient diesen "Titel', value: false},
                comment: {lable: 'Kommentar', value: false},
            },
            ratings: [
                {
                    title: 'Bei diesem Produkt/Sortiment handelt es sich um eine neue Idee und ein schlüssiges Vermarktungskonzept.',
                    value: false
                },
                {title: 'Das optische Erscheinungsbild ist sehr ansprechend.', value: false},
                {title: 'Die Funktionalität der Verpackung und des Produktes ist gegeben.', value: false},
                {title: 'Die Verpackungsart/ -größe/ -einheit bzw. die Formgebung sind innovativ.', value: false},
                {title: 'Der Geschmack der Produktes.', value: false},
                {title: 'Die Vermarktung/Werbebotschaft des Produktes ist besonders und innovativ.', value: false},
                {title: 'Das Produkt liefert einen besonderen Nutzen für den Konsument:innen.', value: false},
                {title: 'Das Produkt liefert einen besonderen Nutzen für den Lebensmittelhandel.', value: false},
            ],
            header: true as boolean,
            disabled: true as boolean,
            commentInnovation: '' as string | undefined
        }
    },
    computed: {
        getContest(){
            if(currentContest.value){
                return currentContest.value
            }else{
                return false
            }
        },
        isCurrentlyLoading(): boolean {
            return isLoading.value
        },

        getCheeseInfos(): Cheese | null {
            if (this.$route.params.cheeseID) {
                if (currentContest.value) {
                    return currentContest.value.getParticipant(Number(this.$route.params.cheeseID))
                }
            }
            return null
        },
        getTableId() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get('tableID')
        },
        getOrigin() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get('origin')
        }
    },

    methods: {
        async showVerifyModal(): Promise<any> {
            this.isDisabled()
            if (!this.disabled) {
                this.initErrors()
                const modal = await modalController
                    .create({
                        component: VerifyRating,
                        componentProps: {
                            type: this.getCheeseInfos?.innovation,
                            points: this.getCheeseInfos?.resultPoints,
                            tableId: this.getTableId,
                            origin: this.getOrigin,
                            questions: this.ratings
                        },
                        cssClass: 'custommodal'
                    })
                return modal.present();
            }
        },

        initErrors(): void {
            for (const err of this.ratings) {
                err.value = false
            }
            this.error.answer9.value = false;
            this.error.comment.value = false;
            this.error.show = false
        },

        setVote(vote: number, index: number) {

            const voteElem = this.$refs['rating' + vote] as HTMLElement
            const points = (this.getCheeseInfos?.resultPoints as ResultPoints)
            setLoadingState(true);

            if (!points) {
                this.getCheeseInfos?.createResultPoints(('answer' + (vote + 1)), (index - 1))
            } else {
                points[('answer' + (vote + 1))] = index - 1;
                points.save()
            }

            Array.from(voteElem.children).forEach(elem => {
                elem.classList.remove('active')
            });
            voteElem.children[index - 1].classList.add('active')
            this.initErrors()
        },
        setComment(event: any): void {
            const points = (this.getCheeseInfos?.resultPoints as ResultPoints)
            setLoadingState(true);

            if (!points) {
                this.getCheeseInfos?.createResultPoints('commentInnovation', event.target?.defaultValue)
            } else {
                points.commentInnovation = event.target?.defaultValue;
                points.save()
            }

            this.initErrors()
        },
        isDisabled(): void {
            const points = (this.getCheeseInfos?.resultPoints as ResultPoints)
            if (points) {

                if (points.answer1 === undefined) this.ratings[0].value = true;
                this.error.show = true;
                if (points.answer2 === undefined) this.ratings[1].value = true;
                this.error.show = true;
                if (points.answer3 === undefined) this.ratings[2].value = true;
                this.error.show = true;
                if (points.answer4 === undefined) this.ratings[3].value = true;
                this.error.show = true;
                if (points.answer5 === undefined) this.ratings[4].value = true;
                this.error.show = true;
                if (points.answer6 === undefined) this.ratings[5].value = true;
                this.error.show = true;
                if (points.answer7 === undefined) this.ratings[6].value = true;
                this.error.show = true;
                if (points.answer8 === undefined) this.ratings[7].value = true;
                this.error.show = true;
                if (points.answer9 === undefined) this.error.answer9.value = true;
                this.error.show = true;
                if (points.commentInnovation === undefined) this.error.comment.value = true;
                this.error.show = true;
                this.disabled = !(points.answer1 !== undefined && points.answer2 !== undefined && points.answer3 !== undefined && points.answer4 !== undefined
                    && points.answer5 !== undefined && points.answer6 !== undefined && points.answer7 !== undefined && points.answer8 !== undefined && points.answer9 !== undefined && points.commentInnovation !== undefined);
            }
        },
        init() {
            fetchResultPoints().then(() => {
                const points = (this.getCheeseInfos?.resultPoints as ResultPoints)
                if (points) {
                    const voteElem = document.querySelectorAll('.rating-container')

                    Array.from(voteElem).forEach((elem, index) => {
                        if (points['answer' + (index + 1)]) {
                            elem.children[Number(points['answer' + (index + 1)])].classList.add('active')
                        }
                    });
                    this.commentInnovation = points.commentInnovation
                }
            })
        },
    },

    watch: {
        getContest: {
            immediate: true,
            handler(newVal, oldVal) {
                if(newVal){
                    if (!this.$route.params.cheeseID || isNaN(Number(this.$route.params.cheeseID))) {
                        this.$router.replace('/cheeselist/')
                    }
                    this.init()
                }
            },
        },
    },
})
